import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as JwtService from '@/services/jwt.service';

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL = 'https://api.dev.ownhome.co/';
    if (process.env.NODE_ENV) {
      Vue.axios.defaults.baseURL = process.env.NODE_ENV === 'dev' ? 'https://strapi.dev.ownhome.co/' : 'https://api.dev.ownhome.co/';
    } else {
      Vue.axios.defaults.baseURL = window.location.href
        .includes('ownhome-frontend.pages.dev' || 'localhost:8080') ? 'https://strapi.dev.ownhome.co/' : 'https://api.dev.ownhome.co/';
    }
    console.log(Vue.axios.defaults.baseURL);
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch((error) => {
      // console.log(error);
      throw new Error(`ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = '') {
    return new Promise((resolve, reject) => {
      const token = JwtService.getToken();
      if (token) {
        Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      Vue.axios.get(`${resource}/${slug}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return new Promise((resolve, reject) => {
      const token = JwtService.getToken();
      if (token) {
        Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      Vue.axios.post(`${resource}`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default ApiService;
