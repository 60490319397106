import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import Dashboard from '@/views/Dashboard.vue';

import { VERIFY_AUTH } from '@/store/auth.module';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/apply',
    name: 'Application',
    component: () => import('../views/Application/pages/Application.vue'),
    meta: { requiresApplication: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/pages/LoginPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch(VERIFY_AUTH);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated && store.getters.hasUserApplied) {
      next();
      return;
    }
    next('/apply');
    return;
  }

  if (to.matched.some((record) => record.meta.requiresApplication)) {
    if (!store.getters.isAuthenticated || !store.getters.hasUserApplied) {
      next();
      return;
    }
    next('/dashboard');
    return;
  }

  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
