import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { BootstrapVue } from 'bootstrap-vue';
import ApiService from '@/services/api.service';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(BootstrapVue);

// API service init
ApiService.init();

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
