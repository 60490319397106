<template>
  <div class="dashboard px-md-22 mx-sm-6 mx-md-0">
    <b-row class="dashboard-container">
      <b-col lg="6" sm="12" class="px-lg-0">
        <h2 class="dashboard-title text-uppercase font-weight-bolder">Welcome, {{ name }}</h2>
        <p class="dasboard-subtitle">Congratulations for being pre-qualified for OwnHome</p>
        <div class="col-lg-10 px-0">
          <b-row >
            <b-col lg="12" class="mb-5">
              <b-card>
                <p class="card-description font-size-lg">
                  Your estimated buying power with OwnHome.
                </p>
                <h2 class="card-sum" v-if="!isLoading">
                  {{ buyingPowerRangeMin }} - {{ buyingPowerRangeMax }}
                </h2>
                <div
                  v-else
                  class="text-center">
                  <b-spinner variant="primary"></b-spinner>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" class="mb-5">
              <b-card class="card-mini">
                <div class="card-description">
                  Estimated monthly payment
                </div>
                <div class="card-sum" v-if="!isLoading">
                  {{ estMonthlyPaymentMin }} - {{ estMonthlyPaymentMax }}
                </div>
                <div
                  v-else
                  class="text-center">
                  <b-spinner variant="primary"></b-spinner>
                </div>
              </b-card>
            </b-col>
            <b-col lg="6" class="mb-5">
              <b-card class="card-mini">
                <div class="card-description">
                  One time starter cost
                </div>
                <div class="card-sum" v-if="!isLoading">
                  {{ oneTimeStarterCost }}
                </div>
                <div
                  v-else
                  class="text-center">
                  <b-spinner variant="primary"></b-spinner>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <b-button
                v-if="isWidgetInit"
                variant="danger"
                block
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/d/dnw8-2ky9/ownhome-customers'});return false;"
              >Schedule a call</b-button>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col lg="6" class="px-0 d-none d-lg-block">
        <img src="../assets/dashboard.jpg" alt=""/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_APPLICATIONS, GET_CONFIG } from '../store/application.module';

export default {
  name: 'Dashboard',
  components: {},
  data() {
    return {
      config: {},
      application: {},
      isLoading: true,
      isWidgetInit: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'applicationId']),
    name() {
      if (!this.currentUser || !this.currentUser.name) return '';
      const currentUserFullName = this.currentUser.name;
      return currentUserFullName.split(' ')[0];
    },
    combinedGrossIncome() {
      const { income, cobuyerincome } = this.application;
      return cobuyerincome ? (Number(income) + Number(cobuyerincome)) : Number(income);
    },
    buyingPowerRangeMin() {
      const { buyingPowerMultiplierMin } = this.config;
      return this.formatNumber(this.combinedGrossIncome * buyingPowerMultiplierMin);
    },
    buyingPowerRangeMax() {
      const { buyingPowerMultiplierMax } = this.config;
      return this.formatNumber(this.combinedGrossIncome * buyingPowerMultiplierMax);
    },
    estMonthlyPaymentMin() {
      const { repaymentMultiplierMin } = this.config;
      return this.formatNumber(this.combinedGrossIncome * repaymentMultiplierMin);
    },
    estMonthlyPaymentMax() {
      const { repaymentMultiplierMax } = this.config;
      return this.formatNumber(this.combinedGrossIncome * repaymentMultiplierMax);
    },
    oneTimeStarterCost() {
      const { depositMultiplier } = this.config;
      return this.formatNumber(this.combinedGrossIncome * depositMultiplier);
    },
  },
  methods: {
    openModal() {
      console.log('Open modal');
    },
    roundUp(number) {
      return Math.ceil(Number(number) / 100) * 100;
    },
    formatNumber(value) {
      const number = this.roundUp(value);
      return `$${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    },
  },
  async created() {
    await this.$store.dispatch(GET_CONFIG)
      .then((data) => {
        this.config = data;
      })
      .catch(() => {
        console.log('Error');
      });
    await this.$store.dispatch(GET_APPLICATIONS, this.applicationId)
      .then((data) => {
        this.application = data;
        this.isLoading = false;
      })
      .catch(() => {
        console.log('Error');
      });
    const ckCDN = document.createElement('script');
    ckCDN.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    document.head.appendChild(ckCDN);
    const ckStyles = document.createElement('link');
    ckStyles.setAttribute('href', 'https://assets.calendly.com/assets/external/widget.css');
    ckStyles.setAttribute('rel', 'stylesheet');
    document.head.appendChild(ckStyles);
    this.isWidgetInit = true;
  },
};
</script>
