<template>
  <b-navbar toggleable="lg" type="light" class="py-lg-4 px-md-22 px-sm-6 oh-navbar">
    <div class="navbar-container">
      <b-navbar-brand href="https://ownhome.co">
        <img alt="OwnHome logo" src="../../assets/logo.svg" width="150">
      </b-navbar-brand>

      <b-navbar-toggle
        v-if="authWithHeader"
        target="nav-collapse"></b-navbar-toggle>

      <b-collapse
        v-if="authWithHeader"
        id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item
            to="/dashboard"
            :class="{'is-active': subIsActive('/dashboard')}">Dashboard</b-nav-item>
          <b-nav-item
            href="https://ownhome.co/how-it-works"
            :class="{'is-active': subIsActive('/how-it-works')}">How it works</b-nav-item>
          <div @mouseover="onOver" @mouseleave="onLeave">
            <b-nav-item-dropdown text="About" ref="dropdown">
              <b-dropdown-item href="https://ownhome.co/faq">FAQ</b-dropdown-item>
              <b-dropdown-item href="https://ownhome.co/contact">Contact</b-dropdown-item>
            </b-nav-item-dropdown>
          </div>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-button
            size="sm"
            variant="link"
            class="logout-link" @click="logOut" type="submit">Log out</b-button>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGOUT } from '../../store/auth.module';

export default {
  name: 'AppNavBar',
  computed: {
    ...mapGetters(['isAuthenticated', 'hasUserApplied']),
    authWithHeader() {
      return this.isAuthenticated && this.hasUserApplied;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch(LOGOUT);
      window.location.href = 'https://ownhome.co';
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => this.$route.path.indexOf(path) === 0);
    },
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
  },
};
</script>
<style lang="scss">
</style>
