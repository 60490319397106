import ApiService from '@/services/api.service';

// action types
import { SET_ERROR } from './auth.module';

export const UPDATE_APPLICATION = 'updateApplication';
export const SEND_APPLICATION = 'sendApplication';
export const GET_CONFIG = 'getConfig';
export const GET_APPLICATIONS = 'getApplications';

// mutation types
export const SET_APPLICATION = 'setApplication';

const getters = {
  hasCobuyer(state) {
    return !!(state.form && state.form.cobuyeremail);
  },
};

const actions = {
  [UPDATE_APPLICATION](context, payload) {
    context.commit(SET_APPLICATION, payload);
  },
  [SEND_APPLICATION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/applications', payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
    });
  },
  [GET_APPLICATIONS](context, payload) {
    return new Promise((resolve, reject) => {
      const applicationId = payload;
      ApiService.get('/applications', applicationId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
    });
  },
  [GET_CONFIG](context) {
    return new Promise((resolve, reject) => {
      ApiService.get('/config')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
    });
  },
};

const mutations = {
  [SET_APPLICATION](state, payload) {
    state.form = {
      ...state.form || {},
      ...payload,
    };
  },
};

const state = {
  form: {},
};

export default {
  state,
  actions,
  mutations,
  getters,
};
