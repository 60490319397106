import ApiService from '@/services/api.service';
import * as JwtService from '@/services/jwt.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('/auth/local', credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('/auth/local/register', credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
    });
  },
  async [VERIFY_AUTH](context) {
    try {
      const token = await JwtService.getToken();
      let currentUser = null;

      if (token) {
        ApiService.setHeader();
        currentUser = await ApiService.get('users/me');
      }
      context.commit(SET_AUTH, { user: currentUser.data, jwt: token });
    } catch (error) {
      context.commit(SET_ERROR, error);
      context.commit(PURGE_AUTH);
    }
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
    state.loadingInit = false;
  },
  [SET_AUTH](state, payload) {
    state.isAuthenticated = true;
    state.hasApplied = payload.user.application > 0;
    state.user = payload.user;
    state.errors = {};
    JwtService.saveToken(payload.jwt);
    state.loadingInit = false;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.hasApplied = false;
    state.loadingInit = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

const getters = {
  currentUser: (state) => state.user,
  isAuthenticated: (state) => state.isAuthenticated,
  hasUserApplied: (state) => state.hasApplied,
  loadingInit: (state) => !!state.loadingInit,
  applicationId: (state) => state.user.application,
};

const state = {
  errors: null,
  user: {},
  hasApplied: false,
  isAuthenticated: false,
  loadingInit: true,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
