<template>
  <div id="app" v-if="!loadingInit">
    <app-nav-bar></app-nav-bar>
    <router-view/>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppNavBar from '@/components/core/AppNavBar.vue';
import AppFooter from '@/components/core/AppFooter.vue';
import { mapGetters } from 'vuex';

import { VERIFY_AUTH } from '@/store/auth.module';

export default {
  name: 'App',
  components: {
    AppNavBar,
    AppFooter,
  },
  computed: {
    ...mapGetters({
      loadingInit: 'loadingInit',
    }),
  },
  created() {
    this.$store.dispatch(VERIFY_AUTH);
  },
};
</script>

<style lang="scss">
  @import 'assets/style/style.vue.scss';
</style>
